import { TALMACEN } from '@/utils/consts'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectAlmacen (Vue, idalmacen) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addNE('idalmacen', idalmacen)
      .addExact('clasificacion', TALMACEN.clasificaciones.taller)
    const resp = await Vue.$api.call('almacen.select', { filter: apiFilter })
    return resp.data.result.dataset
  },
}
