<template>
  <b10-base>
    <b10-autocomplete
      v-model="form.idalmacen"
      :items="almacen"
      item-value="idalmacen"
      item-text="descripcion"
      label="Almacén de descarga"
      clearable
      :rules="formRules.idalmacen"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './DescargaVehiculoFormData'

export default {
  mixins: [formMixin],
  props: {
    usuarioIdalmacen: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      form: {
        idalmacen: null,
      },
      formRules: {
        idalmacen: [
          v => !!v || 'Campo requerido'
        ],
      },
      almacen: [],
    }
  },
  async created () {
    this.almacen = await Data.selectAlmacen(this, this.usuarioIdalmacen)
    if (this.almacen.length === 1) {
      this.$set(this.form, 'idalmacen', this.almacen[0].idalmacen)
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
  }
}
</script>
